import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Button, FormControl, Spinner,
} from 'react-bootstrap';
import { cartBulkAddActions, cartLoadActions, setGuestCustomCartItems } from 'app/store/cart/actions';
import cartApis from 'api/cart';
import { gaLogCustomEvents, gaLogUserLogin } from 'ecommerce_ga_events/EcommerceGAEvents';
import { singularRegistrationComplete } from 'singular';
import * as referalActionTypes from 'app/store/referrals/actions';
import Cart from 'app/pages/cart/Cart';
import {
  CustomModal, LocationSearch,
} from '../../components/common';
import {
  Constants, Storage, validateRegex, validateForm, Utils,
} from '../../utilities';
import {
  otp as generateOtp, signUp,
  synctohomedelivery, deviceInfo,
} from '../../../api/api';
import * as Actions from '../../store/Actions';
import * as Clevertap from '../../../clevertap/Clevertap';
import * as LogClevertapEvent from '../../../clevertap/LogEvent';
import * as screens from '../../events/screens';
import * as Freshchat from '../../../freshchat/Freshchat';
import { isGuestUser } from '../../utilities/Utils';
import { fbLogCustomEvent } from '../../../facebook/Facebook';

const ACCESS_TOKEN_TTL = 23 * 60 * 60 * 1000;

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      otp: '',
      phoneNumber: '',
      stage: 'phoneNumber',
      errors: {},
      loading: false,
      otpResendTimer: 0,
      generatingOTP: false,
    };
    this.phoneNumberRef = React.createRef();
    this.otpRef = React.createRef();
    this.resendOTPInterval = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { stage: prevStage } = prevState;
    const { stage } = this.state;
    const { tryLogin } = this.props;
    const { tryLogin: prevTryLogin } = prevProps;
    if ((tryLogin && stage !== prevStage) || prevTryLogin !== tryLogin) {
      switch (stage) {
        default: break;
        case 'phoneNumber':
          this.phoneNumberRef.current.focus();
          break;
        case 'otp':
          this.otpRef.current.focus();
          break;
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { customer, selectedAddress } = props;
    if (state.stage !== 'address' && customer && !selectedAddress && !isGuestUser()) {
      return ({ stage: 'address' });
    }
    return null;
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (!validateRegex(name, value)) {
      return;
    }
    const { errors } = this.state;
    delete errors[name];
    this.setState({ errors, [name]: value });
  }

  isValidated = (fields) => {
    let { errors } = this.state;
    errors = validateForm(errors, fields);
    if (Object.keys(errors).length === 0) {
      return true;
    }
    this.setState({
      errors,
      loading: false,
    });
    return false;
  }

  submitError = (error) => {
    const { language } = this.props;
    this.setState((state) => {
      const { errors } = state;
      errors.submit = error || Constants.String.OOPS[language];
      return {
        errors,
        loading: false,
      };
    });
  }

  sendOtp = () => {
    this.setState({ generatingOTP: true });
    const { phoneNumber } = this.state;
    LogClevertapEvent.authOtpRequested('User Login Request');
    generateOtp('post', {
      phone_number: phoneNumber,
      role: 'CUSTOMER',
    }).then(() => {
      this.setState({
        generatingOTP: false,
        stage: 'otp',
        loading: false,
      });
      this.setOtpResendTimer();
    }).catch(() => {
      this.setState({
        generatingOTP: false,
      });
      this.submitError();
    });
  }

  setOtpResendTimer = () => {
    this.setState({
      otpResendTimer: 30,
    }, () => {
      this.resendOTPInterval = setInterval(() => {
        const { otpResendTimer } = this.state;
        if (otpResendTimer < 1) {
          clearInterval(this.resendOTPInterval);
          return;
        }
        this.setState((state) => ({
          otpResendTimer: state.otpResendTimer - 1,
        }));
      }, 1000);
    });
  }

  addGuestCustomProducts = (cartDetails) => {
    const { guestCustomCartItems, cartIdLoadRequest, setUpdatedCustomCartItems } = this.props;
    const productIds = Object.keys(guestCustomCartItems || {});
    productIds.forEach((id, index) => {
      cartApis.cartCustomProductAdd(
        cartDetails.cartId,
        guestCustomCartItems[id],
      ).then(() => {
        if (productIds.length === index + 1) {
          setUpdatedCustomCartItems(null);
          cartIdLoadRequest();
        }
      })
        .catch(() => { });
      return 0;
    });
  };

  afterLogingProcess = (customer, cartDetails, onCartIdLoadSuccess) => {
    const { setUpdatedCustomCartItems, addressLoadRequest } = this.props;
    const { cartId } = cartDetails.res;
    const {
      recentAddressLoadRequest,
      toggleLogin,
      updateCustomer,
      history,
      toggleGuestLocation,
      isMobile,
      selectedAddress,
      cartBulkAddRequest,
      toggleSideOverlay,
    } = this.props;

    const onFailure = {
      type: 'sync',
      callback: () => {
        this.setState({
          stage: 'phoneNumber',
          otp: '',
        });
        this.submitError();
      },
    };

    let currentPathName = '/';
    if (
      history.location.pathname !== '/guest-location'
    ) {
      currentPathName = `${history.location.pathname}${history.location.search}`;
    }

    addressLoadRequest(
      {
        type: 'async',
        callback: (adddressData, addressLoadSuccess) => {
          if (adddressData.res.length > 0) {
            Storage.setGuestUserAddresses(null);
            let guestUserCartItems = Storage.getCartItems() || {};
            Storage.setCartItems(null);
            addressLoadSuccess(adddressData);
            onCartIdLoadSuccess(cartDetails);
            updateCustomer(customer);
            const activeAddress = adddressData.res;
            toggleLogin();
            Clevertap.onUserLogin(customer);
            LogClevertapEvent.loginSuccessful(
              Utils.getDateLocaleString(),
              (activeAddress?.activeAddress?.addressText) || '',
            );
            Freshchat.initFreshchat();
            Clevertap.checkNotificationPermission();

            recentAddressLoadRequest();

            // guest user cart poduct addition
            guestUserCartItems = Object.keys(guestUserCartItems)
              .reduce((acc, productId) => {
                delete guestUserCartItems[productId].productInfo;
                return ([...acc, guestUserCartItems[productId]]);
              }, []);
            const bulkRequestBody = guestUserCartItems.map((item) => ({
              retailerProductId: item.productId,
              quantity: item.quantity,
            }));
            const bulkCartAddRequest = (retry = 1) => {
              if (retry < 3) {
                cartBulkAddRequest(
                  {
                    type: 'async',
                    callback: (cartData, cartBulkAddSuccess) => {
                      cartBulkAddSuccess(cartData);
                      this.addGuestCustomProducts(cartData.res);
                    },
                  },
                  {
                    type: 'sync',
                    callback: () => {
                      if (bulkRequestBody.length) {
                        cartApis.cartReset(cartId).then(() => {
                          bulkCartAddRequest(retry + 1);
                        }).catch(() => {
                          bulkCartAddRequest(retry + 1);
                        });
                      }
                    },
                  },
                  {},
                  bulkRequestBody,
                );
              } else {
                setUpdatedCustomCartItems(null);
              }
            };
            if (bulkRequestBody.length) {
              bulkCartAddRequest();
            }

            // redirecting to page
            if (
              selectedAddress?.isDummy
              && history.location.pathname === '/checkout'
            ) {
              history.push('/');
            } else if (
                history?.location?.state?.isCartModal
            ) {
              toggleSideOverlay(Cart);
            } else if (
              isMobile && history.location.pathname === '/guestUserCart'
            ) {
              history.push('/cart');
            } else {
              history.push(currentPathName);
            }

            toggleGuestLocation(false);
          } else {
            addressLoadSuccess(adddressData);
            updateCustomer(customer);
            Clevertap.onUserLogin(customer);
            LogClevertapEvent.loginSuccessful(
              Utils.getDateLocaleString(),
            );
            Freshchat.initFreshchat();
          }
        },
      },
      onFailure,
    );
  }

  user = (retryCount = 3) => {
    const { updateAccessToken } = this.props;
    const {
      phoneNumber, otp,
    } = this.state;
    const timeStamp = Date.now();
    signUp(
      'post',
      {
        phone_number: phoneNumber,
        role: 'CUSTOMER',
        otp,
      },
      null,
      otp,
    ).then((res) => {
      const {
        addAddress,
        clearSelectedAddress,
        selectedAddress,
        cartIdLoadRequest,
        loadReferralsReq,
        referralCodeReq,
      } = this.props;
      const customer = res.data.data;
      Storage.setToken(customer.token);
      Storage.setProfile(customer);
      Storage.setAccessToken(customer.access_token);
      Storage.setRefreshToken(customer.refresh_token);
      Storage.setLastAccessTokenUpdatedAtEpoch(new Date().getTime());
      Storage.setCloseOrderTracker('');
      setTimeout(() => {
        updateAccessToken();
      }, (new Date().getTime()) + (ACCESS_TOKEN_TTL));
      Storage.setUUID(customer.uid);
      gaLogCustomEvents(
        'web_sign_in_successful',
        {
          phoneNo: phoneNumber,
          userId: customer.uid,
          area: '',
          pinCode: '',
          hexId: '',
          city: '',
        },
      );
      LogClevertapEvent.otpSubmission(
        'User Logged In',
        Utils.getTimeElapsedInSec(timeStamp),
      );
      fbLogCustomEvent('Sign Up Successful');
      gaLogUserLogin(customer.uid);
      singularRegistrationComplete(customer.name, customer.email);
      this.syncLanguage();
      this.patchDeviceInfo();
      if (selectedAddress && selectedAddress.isDummy) {
        clearSelectedAddress();
      }
      cartIdLoadRequest(
        {
          type: 'async',
          callback: (cartDetails, onCartIdLoadSuccess) => {
            let guestUserAddresses = Storage.getGuestUserAddresses() || [];
            guestUserAddresses = guestUserAddresses.reduce((acc, loc) => {
              const temp = loc;
              delete temp.locationId;
              return ([...acc, temp]);
            }, []);
            const activeGuestUserAddresses = guestUserAddresses.filter(
              (i) => i.isDefault && !i.isDummy,
            );
            if (activeGuestUserAddresses.length > 0) {
              addAddress(
                activeGuestUserAddresses[0],
                {
                  type: 'async',
                  callback: (response, addBulkAddressSuccess) => {
                    if (addBulkAddressSuccess) {
                      addBulkAddressSuccess(response);
                    }
                    this.afterLogingProcess(customer, cartDetails, onCartIdLoadSuccess);
                  },
                },
                {
                  type: 'async',
                  callback: (e, callback) => {
                    if (callback) {
                      callback();
                    }
                    this.afterLogingProcess(customer, cartDetails, onCartIdLoadSuccess);
                  },
                },
              );
            } else {
              this.afterLogingProcess(customer, cartDetails, onCartIdLoadSuccess);
            }
          },
        },
        {
          type: 'sync',
          callback: () => {
            this.submitError();
          },
        },
      );
      loadReferralsReq();
      referralCodeReq();
    }).catch((error) => {
      if (
        error
        && error.response
        && error.response.status === 401
        && error.response.data
        && error.response.data[0]
      ) {
        this.submitError(error.response.data[0].client_message);
        return;
      }
      if (retryCount > 0) {
        setTimeout(() => {
          this.user(retryCount - 1);
        }, 200);
        return;
      }
      this.submitError();
    });
  }

  onPhoneNumberSubmit = (event) => {
    const { phoneNumber, errors } = this.state;
    if (event) {
      event.preventDefault();
      delete errors.submit;
      this.setState({
        errors,
        loading: true,
      });
    }
    if (this.isValidated({ phoneNumber })) {
      this.sendOtp();
    }
  }

  onOtpSubmit = (event) => {
    event.preventDefault();
    const { otp, errors } = this.state;
    delete errors.submit;
    this.setState({
      errors,
      loading: true,
    });
    if (this.isValidated({ otp })) {
      this.user();
    }
  }

  syncLanguage = (count = 0) => {
    const { language } = this.props;
    synctohomedelivery(
      'PATCH',
      Storage.getUUID(),
      {
        language_code: language,
      },
    ).then(() => {})
      .catch(() => {
        if (count + 1 > 3) {
          return;
        }
        this.syncLanguage(count + 1);
      });
  }

  patchDeviceInfo = (count = 0) => {
    deviceInfo(
      'patch',
      Storage.getUUID(),
      {
        deviceId: Storage.getDeviceId(),
        info: {
          source: 'WEB',
        },
      },
    ).then(() => {})
      .catch(() => {
        if (count + 1 > 3) {
          return;
        }
        this.patchDeviceInfo(count + 1);
      });
  }

  reset = () => {
    this.setState({
      otp: '',
      phoneNumber: '',
      stage: 'phoneNumber',
      errors: {},
      loading: false,
      // tcRequestId: null,
    });
  }

  renderLoginState = () => {
    const {
      stage, phoneNumber, otp, errors, loading,
      otpResendTimer, generatingOTP,
    } = this.state;
    const {
      isMobile, language, toggleLogin, history,
    } = this.props;
    switch (stage) {
      case 'getStarted': {
        return (
          <div
            className="text-center"
          >
            <div className="border-top mx-n5 pb-4" />
            {loading ? (
              <Spinner
                animation="border"
                variant="primary"
              />
            )
              : (
                <Button
                  className="fs-2 w-100"
                  onClick={() => {
                    this.setState({
                      stage: 'phoneNumber',
                    });
                    fbLogCustomEvent('GetStartedButtonClicked');
                  }}
                >
                  {Constants.String.GET_STARTED[language]}
                </Button>
              )}
            {errors.submit && (
            <div
              className="text-primary fs-5"
            >
              {errors.submit}
            </div>
            )}
          </div>
        );
      }
      case 'phoneNumber': {
        return (
          <form
            onSubmit={this.onPhoneNumberSubmit}
          >
            <Row>
              <Col
                xs={24}
                className="fs-2 mb-4 font-weight-bold"
              >
                {Constants.String.PLEASE_ENTER_PHONE_NUMBER[language]}
              </Col>
              <Col
                lg={24}
                className="d-none d-lg-block"
              >
                <div
                  className="mb-4 border-bottom"
                />
              </Col>
              <Col
                xs={24}
                className="mb-4"
              >
                <FormControl
                  ref={this.phoneNumberRef}
                  name="phoneNumber"
                  type="text"
                  size="lg"
                  value={phoneNumber}
                  placeholder={Constants.String.ENTER_MOBILE[language]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  isInvalid={errors.phoneNumber}
                />
                <FormControl.Feedback
                  type="invalid"
                >
                  {errors.phoneNumber && errors.phoneNumber[language]}
                </FormControl.Feedback>
              </Col>
              {
                errors.submit && (
                  <Col
                    xs={24}
                    className="text-primary fs-5 mb-4"
                  >
                    {errors.submit}
                  </Col>
                )
              }
              <Col
                xs={24}
                className={`mb-4 ${loading ? 'text-center' : ''}`}
              >
                {
                  loading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  ) : (
                    <Button
                      id="phone-number-submit"
                      block={isMobile}
                      className="fs-2 py-2 px-6"
                      onClick={this.onPhoneNumberSubmit}
                    >
                      {Constants.String.NEXT[language]}
                    </Button>
                  )
                }
              </Col>
            </Row>
          </form>
        );
      }

      case 'otp': {
        return (
          <form
            onSubmit={this.onOtpSubmit}
          >
            <Row>
              <Col
                xs={24}
                className="fs-2 mb-4 font-weight-bold"
              >
                {Constants.String.PLEASE[language]}
                &nbsp;
                {Constants.String.ENTER_OTP[language]}
              </Col>
              <Col
                lg={24}
                className="d-none d-lg-block"
              >
                <div
                  className="mb-4 border-bottom"
                />
              </Col>
              <Col
                xs={24}
                className="mb-4"
              >
                <FormControl
                  ref={this.otpRef}
                  name="otp"
                  type="text"
                  size="lg"
                  value={otp}
                  placeholder={Constants.String.ENTER_OTP[language]}
                  onChange={this.handleChange}
                  autoComplete="off"
                  isInvalid={errors.otp}
                />
                <FormControl.Feedback
                  type="invalid"
                >
                  {errors.otp && errors.otp[language]}
                </FormControl.Feedback>
              </Col>
              <Col
                xs={24}
                className="mb-4 text-medium"
              >
                {
                  otpResendTimer < 1 ? (
                    <div
                      className="d-flex align-items-center"
                    >
                      <span>
                        {Constants.String.DIDNOT_GET_OTP[language]}
                      </span>
                      <Button
                        variant="link"
                        className="px-2 py-0"
                        onClick={() => this.sendOtp()}
                        disabled={generatingOTP}
                      >
                        <span
                          className="font-weight-normal"
                        >
                          {Constants.String.RESEND_OTP[language]}
                        </span>
                      </Button>
                    </div>
                  ) : (
                    <span>
                      {`${
                        Constants.String.RESEND_OTP[language]
                      } (00:${
                        otpResendTimer < 10 ? `0${otpResendTimer}` : otpResendTimer
                      })`}
                    </span>
                  )
                }
              </Col>
              {
                errors.submit && (
                  <Col
                    xs={24}
                    className="text-primary fs-5 mb-4"
                  >
                    {errors.submit}
                  </Col>
                )
              }
              <Col
                xs={24}
                className={`mb-4 ${loading ? 'text-center' : ''}`}
              >
                {
                  loading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                    />
                  ) : (
                    <Button
                      id="otp-submit"
                      block={isMobile}
                      className="fs-2 py-2 px-6"
                      onClick={this.onOtpSubmit}
                    >
                      {Constants.String.NEXT[language]}
                    </Button>
                  )
                }
              </Col>
            </Row>
          </form>
        );
      }

      case 'address': {
        return (
          <Row
            className={isMobile ? 'text-center' : ''}
          >
            <Col
              xs={24}
              className="fs-2 mb-2 mb-lg-4 font-weight-bold"
            >
              {`${Constants.String.ALMOST_THERE[language]}!`}
            </Col>
            <Col
              lg={24}
              className="d-none d-lg-block"
            >
              <div
                className="mb-4 border-bottom"
              />
            </Col>
            <Col
              xs={24}
              className="mb-4"
            >
              {Constants.String.SET_LOCATION_TEXT[language]}
            </Col>
            <Col
              xs={24}
            >
              <LocationSearch
                isMobile={isMobile}
                language={language}
                screen={screens.SIGN_UP}
                onSuccess={
                  {
                    type: 'async',
                    callback: (data, addLocationSuccess) => {
                      addLocationSuccess();
                      toggleLogin();
                      history.push('/');
                      Clevertap.checkNotificationPermission();
                    },
                  }
                }
              />
            </Col>
          </Row>
        );
      }

      default: {
        return '';
      }
    }
  }

  render() {
    const {
      tryLogin, toggleLogin, isMobile,
    } = this.props;
    const { stage } = this.state;
    const login = (
      <Container
        className="position-relative border-radius-16 overflow-hidden bg-white"
      >
        <Row
          className="position-absolute w-100"
        >
          <Button
            variant="link"
            className={`ml-auto z-index-1 px-2 py-1 ${isMobile ? 'text-white bg-primary' : 'text-black'}`}
            style={{
              fontSize: 32,
              lineHeight: 1,
            }}
            onClick={() => {
              toggleLogin();
            }}
          >
            &times;
          </Button>
        </Row>
        <Row>
          <Col
            xs={24}
            lg={10}
            className="bg-white text-center text-white border p-4"
          >
            <img
              alt=""
              src="/images/onboarding-lady.jpg"
              className="w-100 border-radius-8"
            />
          </Col>
          <Col
            xs={24}
            lg={12}
            className="mt-lg-2 px-5 pb-5 pt-lg-5"
          >
            {this.renderLoginState()}
          </Col>
        </Row>
      </Container>
    );
    return (
      <CustomModal
        show={tryLogin}
        body={login}
        size="lg"
        onHide={() => {
          toggleLogin();
        }}
        reset={this.reset}
        backdrop={stage === 'getStarted'}
      />
    );
  }
}

Login.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  customer: PropTypes.shape({}),
  selectedAddress: PropTypes.shape({
    isDummy: PropTypes.bool,
  }),
  tryLogin: PropTypes.bool.isRequired,
  addressLoadRequest: PropTypes.func.isRequired,
  addAddress: PropTypes.func.isRequired,
  recentAddressLoadRequest: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      state: PropTypes.shape({
        isCartModal: PropTypes.bool,
      }),
    }),
    replace: PropTypes.func,
  }).isRequired,
  toggleGuestLocation: PropTypes.func.isRequired,
  clearSelectedAddress: PropTypes.func.isRequired,
  cartIdLoadRequest: PropTypes.func.isRequired,
  cartItems: PropTypes.shape({}),
  cartBulkAddRequest: PropTypes.func.isRequired,
  updateAccessToken: PropTypes.func.isRequired,
  toggleSideOverlay: PropTypes.func.isRequired,
  guestCustomCartItems: PropTypes.shape({}),
  setUpdatedCustomCartItems: PropTypes.func.isRequired,
  loadReferralsReq: PropTypes.func.isRequired,
  referralCodeReq: PropTypes.func.isRequired,
};

Login.defaultProps = {
  customer: null,
  selectedAddress: null,
  cartItems: null,
  guestCustomCartItems: null,
};

const mapStateToProps = (state) => ({
  customer: state.main.customer,
  selectedAddress: state.main.selectedAddress,
  tryLogin: state.main.tryLogin,
  cartItems: state.main.cartItems,
  guestCustomCartItems: state.main.guestCustomCartItems,
});

const mapDispatchToProps = (dispatch) => ({
  addAddress: (address, onSuccess, onFailure) => {
    dispatch(Actions.addAddress(address, onSuccess, onFailure));
  },
  addressLoadRequest: (onSuccess, onFailure) => {
    dispatch(Actions.addressLoadRequest(onSuccess, onFailure));
  },
  recentAddressLoadRequest: (onSuccess, onFailure) => {
    dispatch(Actions.recentAddressLoadRequest(onSuccess, onFailure));
  },
  toggleGuestLocation: (toggleGuestLocationPopup, isMandatoryGuestLocationPopup) => {
    dispatch(Actions.toggleGuestLocation(toggleGuestLocationPopup, isMandatoryGuestLocationPopup));
  },
  toggleLogin: () => {
    dispatch(Actions.toggleLogin());
  },
  updateCustomer: (customer) => {
    dispatch(Actions.updateCustomer(customer));
  },
  clearSelectedAddress: () => {
    dispatch(Actions.clearSelectedAddress());
  },
  cartIdLoadRequest:
  (onSuccess) => {
    dispatch(cartLoadActions.loadRequest(onSuccess));
  },
  cartBulkAddRequest: (onSuccess, onFailure, params, body) => {
    dispatch(cartBulkAddActions.addRequest(onSuccess, onFailure, params, body));
  },
  setUpdatedCustomCartItems: (customCartItems) => {
    dispatch(setGuestCustomCartItems(customCartItems));
  },
  loadReferralsReq: () => {
    dispatch(
      referalActionTypes.referralsLoadActions.loadRequest(),
    );
  },
  referralCodeReq: () => {
    dispatch(Actions.referralCodeGetReq());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
